<template>
  <auth-layout>
    <h2 class="welcome">Selamat Datang</h2>
      <span class="welcome-note">
        Silahkan masuk menggunakan data anda
      </span>
    <form @submit.prevent="submit" method="post">
      <div class="form-group" style="width:491px; margin-top: 100px;">
        <label for="inputEmail">Email</label>
        <input
          id="inputEmail"
          type="text"
          :class="error || msg ? 'form-control is-invalid' : 'form-control login-focus'"
          autocomplete="off"
          v-model="form.username"
          placeholder="Email"
          :disabled="loading"
          @blur="checkEmail"
        />
        <span v-if="checkEmail" class="text-red">{{ msg }}</span>
      </div>
      <div class="form-group">
        <label class="col-form-label" for="inputPassword">Kata Sandi</label>
        <div class="input-group mb-3">
          <input
            id="inputPassword"
            :type="showPassword ? 'text' : 'password'"
            :class="error || msgPwd ? 'form-control is-invalid' : 'form-control login-focus'"
            autocomplete="off"
            v-model="form.password"
            placeholder="Kata Sandi"
            :disabled="loading"
            @blur="checkPassword"
          />
          <div class="input-group-append">
            <span class="input-group-text" @click="showPwd()">
              <i :class="showPassword == true ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
            </span>
          </div>
        </div>
      </div>
      <span v-if="checkPassword" class="text-red" style="display: block; margin-top: -16px; margin-bottom: 25px;">{{ msgPwd }}</span>
      <span v-if="error" class="text-red font-weight-bold" style="display: block; margin-bottom: 25px;">
        <div class="error-message">
          <i class="fas fa-info-circle mr-1 mt-2"></i>{{ error }}
        </div>
      </span>
        <!-- /.col -->
        <div class="row">
          <div class="col-5">
            <button
              type="submit"
              class="btn vm-btn-submit btn-block btn-flat"
              :disabled="loading"
            >
              <i class="fas fa-spinner fa-spin" v-if="loading"></i>
              {{ loading ? "..." : "Masuk" }}
            </button>
          </div>
          <div class="col-6 social-auth-links mb-3 ml-4">
            <router-link :to="{ name: 'forgot_password' }"
              >Lupa Kata Sandi?
            </router-link>
          </div>
        </div>
        <!-- /.col -->
    </form>
  </auth-layout>
</template>

<script>
const { console } = window;
import AuthLayout from "@components/layouts/Auth.vue";
export default {
  components: {
    AuthLayout,
  },
  data: () => ({
    title_submit: "Sign In",
    form: {
      username: '',
      password: ''
    },
    error: undefined,
    loading: false,
    showPassword: false,
    regexEmail:/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    msg: '',
    msgPwd: ''
  }),
  mounted() {
    $("html, body").css('overflow', 'hidden')
    if (Api.getToken()) {
      this.$router.replace({ name: "Dashboard" });
    }
   
    $("body").attr("class", "hold-transition vm-login-page text-sm");
    $("body,html").removeAttr("style");
    new adminlte.Layout("body", {});
  },
  methods: {
    checkEmail(e) {
      if(e.target.value) {
        if(this.regexEmail.test(e.target.value)) {
          this.msg = '';
        } else {
          this.msg = 'Format email tidak sesuai.';
        }
      } else {
        this.msg = 'Email wajib diisi.'
      }
    },
    checkPassword(e) {
      if(e.target.value) {
        this.msgPwd = ''
      } else {
        this.msgPwd = 'Kata sandi wajib diisi.'
      }
    },
    submit() {
      this.title_submit = "Processing...";
      this.loading = true;
        Api.login(this.form.username, this.form.password)
          .then((res) => {
            this.loading = false;
            Api.setToken(res, this.form.remember);
            this.$router.replace({ name: "Dashboard" });
          })
          .catch((err) => {
            this.loading = false;
            this.title_submit = "Sign In";
            
            if(this.form.password && this.form.username) {
              if (err.response.data.error_description == "Bad credentials") {
                this.error = "Email atau Kata sandi Anda salah.";
              }
            } else if(this.form.username === '' && this.form.password === ''){
              this.msg = 'Email wajib diisi.'
              this.msgPwd = 'Kata sandi wajib diisi.'
            } else if(this.form.username === '') {
              this.msg = 'Email wajib diisi.'
            } else if(this.form.password === '') {
              this.msgPwd = 'Kata sandi wajib diisi.'
            }
          });
    },
    showPwd() {
        this.showPassword = !this.showPassword
    },
  },
};
</script>


<style lang="sass">
    .error-message
      background-color: #FFF4F2
      width: 491px
      height: 52px
      border-radius: 6px
      padding: 10px
      
</style>